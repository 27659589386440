import { useState } from "react";
import { query } from "@aglocal/web/helpers/responsive";
import useAbort from "@aglocal/web/hooks/useAbort";
export default function useMatchMedia(options) {
    const [matches, setMatches] = useState(false);
    const queryString = query(options);
    useAbort((signal) => {
        const match = window.matchMedia(queryString);
        match.addEventListener("change", (event) => {
            setMatches(event.matches);
        }, { signal });
        setMatches(match.matches);
    }, [setMatches, queryString]);
    return matches;
}
