// Config for Azure Communication Services to Enable OpCo Cost allocation Vs Experiment
import { BrandId } from "@aglocal/brands";
export var ExtendedBrandId;
(function (ExtendedBrandId) {
    ExtendedBrandId["ACTALENT"] = "ACTALENT";
    ExtendedBrandId["AEROTEK"] = "AEROTEK";
    ExtendedBrandId["ASTON_CARTER"] = "ASTON_CARTER";
    ExtendedBrandId["TEK_SYSTEMS"] = "TEK_SYSTEMS";
    ExtendedBrandId["EXPERIMENT"] = "EXPERIMENT";
})(ExtendedBrandId || (ExtendedBrandId = {}));
