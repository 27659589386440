export var BrandId;
(function (BrandId) {
    BrandId["ACTALENT"] = "ACTALENT";
    BrandId["AEROTEK"] = "AEROTEK";
    BrandId["ASTON_CARTER"] = "ASTON_CARTER";
    BrandId["TEK_SYSTEMS"] = "TEK_SYSTEMS";
})(BrandId || (BrandId = {}));
export const ACTALENT = {
    id: BrandId.ACTALENT,
    name: "Actalent Services",
    origin: "https://www.actalentservices.com",
    talentAssistantOrigin: "https://talentassistant.actalentservices.com",
    privacyPolicyUrl: "/en/privacy-notices",
    termsAndConditionsUrl: "/en/terms-and-conditions",
    hostPattern: /actalentservices\./i,
    minBookingBufferMinutes: 60,
    maxBookingBusinessDays: 10,
};
export const AEROTEK = {
    id: BrandId.AEROTEK,
    name: "Aerotek",
    origin: "https://www.aerotek.com",
    talentAssistantOrigin: "https://talentassistant.aerotek.com",
    privacyPolicyUrl: "/en/privacy-notices",
    termsAndConditionsUrl: "/en/terms-conditions",
    hostPattern: /aerotek\./i,
    minBookingBufferMinutes: 60,
    maxBookingBusinessDays: 3,
};
export const ASTON_CARTER = {
    id: BrandId.ASTON_CARTER,
    name: "Aston Carter",
    origin: "https://www.astoncarter.com",
    talentAssistantOrigin: "https://talentassistant.astoncarter.com",
    privacyPolicyUrl: "/en/privacy-notices",
    termsAndConditionsUrl: "/en/terms-and-conditions",
    hostPattern: /astoncarter\./i,
    minBookingBufferMinutes: 60,
    maxBookingBusinessDays: 10,
};
export const TEK_SYSTEMS = {
    id: BrandId.TEK_SYSTEMS,
    name: "TEKsystems",
    origin: "https://www.teksystems.com",
    talentAssistantOrigin: "https://talentassistant.teksystems.com",
    privacyPolicyUrl: "/privacy-notices",
    termsAndConditionsUrl: "https://www.allegisgroup.com/terms-and-conditions",
    hostPattern: /teksystems\./i,
    minBookingBufferMinutes: 60,
    maxBookingBusinessDays: 10,
};
export const BRANDS = [
    ACTALENT,
    AEROTEK,
    ASTON_CARTER,
    TEK_SYSTEMS,
];
export const BRANDS_BY_ID = {
    [BrandId.ACTALENT]: ACTALENT,
    [BrandId.AEROTEK]: AEROTEK,
    [BrandId.ASTON_CARTER]: ASTON_CARTER,
    [BrandId.TEK_SYSTEMS]: TEK_SYSTEMS,
};
export function getBrandFromHost(host) {
    for (const brand of BRANDS) {
        if (brand.hostPattern.test(host)) {
            return brand;
        }
    }
    return null;
}
export function getBrandFromEmail(email) {
    const parts = email.split("@");
    if (parts.length === 2) {
        return getBrandFromHost(parts[1]);
    }
    return null;
}
export function getPrivacyPolicyUrl(brand) {
    return new URL(brand.privacyPolicyUrl, brand.origin).href;
}
export function getTermsAndConditionsUrl(brand) {
    return new URL(brand.termsAndConditionsUrl, brand.origin).href;
}
